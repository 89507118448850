















import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import OurVision from "./OurVision.vue";
import OurMission from "./OurMission.vue";
import News from "./News.vue";

@Component({
  components: {
    Banner,
    OurVision,
    OurMission,
    News
  }
})
export default class HomeView extends Vue {}

































import { Component, Vue } from "vue-property-decorator";

@Component
export default class OurVision extends Vue {
  items = [
    {
      img: require("@/assets/mission-1.svg"),
      title: "Karşılıksız",
      text:
        "İlköğretimden üniversiteye kadar ulaşabildiğimiz, ihtiyaç sahibi tüm öğrenme tutkunlarına karşılıksız burs veriyoruz."
    },
    {
      img: require("@/assets/mission-2.svg"),
      title: "Kesintisiz",
      text:
        "KAHEV, geleceğimizin aydınlık yüzleri olan çoçuklarımızı ve gençlerimizi her zaman desteklemeyi görev edinmiştir."
    },
    {
      img: require("@/assets/mission-3.svg"),
      title: "Gönülden",
      text:
        "Siz değerli destekçilerimizin katkılarıyla gönülden gönüle köprüler kurarak, eğitimde fırsat eşitliği sunuyoruz."
    }
  ];
}
